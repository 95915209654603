import { PureComponent, createRef } from 'react';

// Authentication provider
import { withAuth } from 'services/auth';

// Authentication provider
import { withRouter } from 'react-router-dom';

// Snackbar provider
import { withSnackbar } from 'notistack';

// Material UI components / helpers
import { Divider, Typography, withStyles } from '@material-ui/core';

// To handle error messages
import { getErrorMessage } from 'helpers';

// Export cleanup
import compose from 'recompose/compose';

// Async status
import { AsyncStatus } from 'components';

// Component styles
import styles from './styles';

class BehaviourView extends PureComponent {
  constructor(props) {
    super(props);

    // Initialzie the state
    this.state = {
      behaviour: undefined,
      error: undefined
    };

    // Create a React ref for the cancel confirm dialog
    this.cancelConfirmDialog = createRef();

    // Bind the callback functions
    this.retryAPICall = this.retryAPICall.bind(this);

    // Initialize the ready flag
    this.ready = true;
  }

  async componentDidMount(store = true) {
    try {
      // Setup references to the API endpoints and
      // the uid of the current user
      const { auth, booking, isGroupBooking } = this.props;

      if (!isGroupBooking) {
        const behaviour = await auth.api.bookings.getFeedbackBehaviour(
          booking.tutor,
          booking.id,
          store
        );
        if (this.ready) {
          this.setState({ behaviour });
        }
      }
    } catch (error) {
      // Update the component's state to show an error
      if (this.ready) {
        this.setState({
          error: getErrorMessage(error)
        });
      }

      // Log the error to the console.
      console.error(error);
    }
  }

  componentWillUnmount() {
    this.ready = false;
  }

  retryAPICall() {
    // Reset the state
    this.setState({
      behaviour: undefined,
      error: undefined
    });

    // Call componentDidMount to call the API again.
    this.componentDidMount(false);
  }

  render() {
    const { behaviour, error } = this.state;
    const { booking, isGroupBooking } = this.props;

    if (error) {
      // Display the error message and retry button
      return <AsyncStatus error={error} onRetry={this.retryAPICall} retry />;
    }

    if (isGroupBooking) {
      return booking.students.map((student, index) => {
        return (
          <>
            <Typography
              variant="h4"
              style={{ marginBottom: '10px' }}
              key={student.id}>
              {student.name} Behaviour
            </Typography>
            <Typography key={student.id}>
              {student.feedback.behaviour}
            </Typography>
            {index < booking.students.length - 1 && (
              <Divider style={{ margin: '20px 0' }} />
            )}
          </>
        );
      });
    }

    // If we haven't retrived the behaviour
    if (!behaviour) return <AsyncStatus loading />;
    // Return the feedback
    return <Typography>{behaviour}</Typography>;
  }
}

export default compose(
  withSnackbar,
  withRouter,
  withAuth,
  withStyles(styles)
)(BehaviourView);
