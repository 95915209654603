import { PureComponent, ReactElement } from 'react';
import { RemoteParticipant } from 'twilio-video';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {
  Participant,
  Room,
  connect,
  LocalVideoTrack,
  LocalAudioTrack,
  LocalTrack,
  LocalAudioTrackPublication,
  LocalVideoTrackPublication,
  createLocalAudioTrack
} from 'twilio-video';
import CallContext, { CallStatus } from './context';
import { createLocalVideoTrack } from 'twilio-video';

interface State {
  status: CallStatus;
  room: Room | null;
  participants: RemoteParticipant[];
  muted: boolean;
  camera: boolean;
  screenSharing: boolean;
  screenTrack: LocalVideoTrack | null;
  error: Error | null;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

class CallProvider extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      status: CallStatus.Disconnected,
      room: null,
      participants: [],
      muted: false,
      camera: true,
      screenSharing: false,
      screenTrack: null,
      error: null
    };

    this.toggleMute = this.toggleMute.bind(this);
    this.switchMic = this.switchMic.bind(this);
    this.toggleCamera = this.toggleCamera.bind(this);
    this.switchCamera = this.switchCamera.bind(this);
    this.participantConnected = this.participantConnected.bind(this);
    this.participantDisconnected = this.participantDisconnected.bind(this);
    this.leave = this.leave.bind(this);
    this.join = this.join.bind(this);
    this.startScreenSharing = this.startScreenSharing.bind(this);
    this.stopScreenSharing = this.stopScreenSharing.bind(this);
  }

  toggleMute(): void {
    const { room, muted } = this.state;

    room?.localParticipant?.audioTracks.forEach((publication) => {
      if (muted) {
        publication.track.enable();
      } else {
        publication.track.disable();
      }
    });

    this.setState({
      muted: !this.state.muted
    });
  }

  async switchMic(
    deviceId: string
  ): Promise<LocalAudioTrackPublication | null> {
    const { room } = this.state;
    if (!room?.localParticipant)
      throw new Error('Cannot switch camera when not in a room!');

    try {
      // Grab the audio track publication
      const trackPubs = Array.from(room.localParticipant.audioTracks.values());
      if (trackPubs.length > 0) {
        // Restart it with the new device ID
        await trackPubs[0].track.restart({
          deviceId
        });
      }

      // Return the track publication
      return trackPubs[0];
    } catch (error) {
      console.error(error);
    }

    return null;
  }

  toggleCamera(): void {
    const { room, camera } = this.state;

    room?.localParticipant?.videoTracks.forEach((publication) => {
      if (publication.track.name !== 'screen-share') {
        // Check if the track is not the screen share track
        if (camera) {
          publication.track.disable();
        } else {
          publication.track.enable();
        }
      }
    });

    this.setState({
      camera: !this.state.camera
    });
  }

  async switchCamera(
    deviceId: string
  ): Promise<LocalVideoTrackPublication | null> {
    const { room } = this.state;
    if (!room?.localParticipant)
      throw new Error('Cannot switch camera when not in a room!');

    try {
      // Grab the video track publication
      const trackPubs = Array.from(room.localParticipant.videoTracks.values());
      if (trackPubs.length > 0) {
        // Restart it with the new device ID
        await trackPubs[0].track.restart({
          deviceId
        });
      }

      // Return the track publication
      return trackPubs[0];
    } catch (error) {
      console.error(error);
    }

    return null;
  }

  async startScreenSharing(): Promise<void> {
    try {
      const options = {
        video: true,
        preferCurrentTab: true,
        surfaceSwitching: 'exclude'
      };
      const stream = await navigator.mediaDevices.getDisplayMedia(options);

      const screenTrack = new LocalVideoTrack(stream.getTracks()[0], {
        name: 'screen-share'
      });
      this.setState({ screenSharing: true, screenTrack });

      // Add an event listener for when the screen sharing is stopped by the browser
      screenTrack.mediaStreamTrack.onended = () => {
        this.stopScreenSharing();
      };

      const { room } = this.state;
      if (room) {
        await room.localParticipant.publishTrack(screenTrack);
      }
    } catch (error) {
      console.error('Error starting screen sharing:', error);
    }
  }

  async stopScreenSharing(): Promise<void> {
    const { screenTrack, room } = this.state;

    if (screenTrack) {
      screenTrack.stop();

      if (room) {
        const trackPublication =
          room.localParticipant.unpublishTrack(screenTrack);
        room.localParticipant.emit('trackUnpublished', trackPublication);
      }

      this.setState({ screenSharing: false, screenTrack: null });
    }
  }

  // Event handlers
  participantConnected(participant: Participant): void {
    const { participants } = this.state;

    console.log(`Participant ${participant.identity} connected!`);
    this.setState({
      participants: [...participants, participant as RemoteParticipant]
    });
  }

  participantDisconnected(participant: Participant): void {
    const { participants } = this.state;

    console.log(`Participant ${participant.identity} disconnected!`);
    this.setState({
      participants: participants.filter((p) => p !== participant)
    });
  }

  leave(): void {
    const { room } = this.state;
    if (room && room.localParticipant.state === 'connected') {
      if (room.localParticipant.state === 'connected') {
        room.localParticipant.tracks.forEach(function ({ track }) {
          if (track instanceof LocalVideoTrack) {
            (track as LocalVideoTrack).stop();
          } else if (track instanceof LocalAudioTrack) {
            (track as LocalAudioTrack).stop();
          }
        });
      }

      room.disconnect();
    }

    // Reset the state
    this.setState({
      status: CallStatus.Disconnected,
      room: null,
      muted: false,
      camera: false,
      participants: [],
      error: null,
      screenSharing: false,
      screenTrack: null
    });
  }

  countdownTimer() {
    const countdownDateTime = new Date().getTime() + 900000;
    const completedLessonTime =
      Math.round((Date.now() - 3600000) / 1000 / 60 / 60) * 60 * 60 * 1000;
    const timeInterval = setInterval(async () => {
      const currentTime = new Date().getTime();
      const remainingDayTime = countdownDateTime - currentTime;
      if (remainingDayTime < 0) {
        const completedLessons = await firebase
          .firestore()
          .collectionGroup('bookings')
          .where('status', '==', 'in-progress')
          .where('when', '<=', completedLessonTime)
          .get();
        if (!completedLessons.empty) {
          completedLessons.docs.forEach((doc) => {
            doc.ref.update({ status: 'tutor-present' });
          });
        }
        clearInterval(timeInterval);
      }
    }, 1000);
  }

  async join(
    token: string,
    roomName: string,
    tracks?: LocalTrack[]
  ): Promise<void> {
    try {
      // Connect to the room
      console.log(`Connecting to ${roomName} room...`, token);

      this.setState({ status: CallStatus.Connecting });
      // Fallback: Create audio and video tracks if they are not provided
      if (!tracks || tracks.length === 0) {
        console.log('No tracks found, creating new tracks...');
        const audioTrack = await createLocalAudioTrack();
        const videoTrack = await createLocalVideoTrack();
        tracks = [audioTrack, videoTrack];
      }
      const room = await connect(token, {
        name: roomName,
        tracks
      });
      console.log(`Connected to ${roomName} room!`);
      // Update the count down every 1 second
      this.countdownTimer();

      // Listen for room events
      room.on('participantConnected', this.participantConnected);
      room.on('participantDisconnected', this.participantDisconnected);

      // Get the supplied tracks and check if muted / camera off
      const localVideoTrack = tracks?.find(
        (track) => track.kind === 'video'
      ) as LocalVideoTrack;
      const localAudioTrack = tracks?.find(
        (track) => track.kind === 'audio'
      ) as LocalAudioTrack;

      // Mute audio and disable video on join
      if (localAudioTrack) localAudioTrack.disable();
      if (localVideoTrack) localVideoTrack.disable();

      // Update the state
      this.setState({
        status: CallStatus.Connected,
        participants: Array.from(room.participants.values()),
        // camera: localVideoTrack ? localVideoTrack.isEnabled : true,
        // muted: localAudioTrack ? !localAudioTrack.isEnabled : false,
        camera: false,
        muted: true,
        room
      });

      return;
    } catch (error: any) {
      this.setState({ error });
      throw error;
    }
  }

  render(): ReactElement {
    const {
      status,
      room,
      participants,
      muted,
      camera,
      error,
      screenSharing,
      screenTrack
    } = this.state;
    const { children } = this.props;
    return (
      <CallContext.Provider
        value={{
          status,
          participants,
          localParticipant: room?.localParticipant,
          connected: room ? true : false,
          muted,
          camera,
          screenSharing,
          screenTrack,
          error,
          toggleMute: this.toggleMute,
          switchMic: this.switchMic,
          toggleCamera: this.toggleCamera,
          switchCamera: this.switchCamera,
          join: this.join,
          leave: this.leave,
          startScreenSharing: this.startScreenSharing,
          stopScreenSharing: this.stopScreenSharing
        }}>
        {children}
      </CallContext.Provider>
    );
  }
}

export default CallProvider;
